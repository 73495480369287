@import "bootstrap";
@import "global";
@import "../widgets/widgets";
@import "../directives/directives";
@import "print";

html,
body {
	-webkit-font-smoothing  : antialiased;
	-moz-osx-font-smoothing : grayscale;
	overscroll-behavior     : none;		// attempt to prevent the bounce-effect when the top or bottom of scrolling is reached
}

#root {
	max-width  : 100%;
	min-height : 100vh; 	// Override if DVH isn't supported
	min-height : 100dvh;	// stylelint-disable-line unit-no-unknown
}
