// styles for all widgets

@media screen {
	.form-group {
		// add asterisk after the label for required fields
		&.required {
			legend,
			label {
				&:not(.btn, .custom-control-label) {
					&::after {
						display     : inline-block;
						position    : static;
						margin-left : 3px;
						content     : '*';
					}
				}
			}
		}

		// slightly bolded form widget labels
		legend,
		label {
			&:not(.btn, .custom-control-label) {
				font-weight : 500;
			}
		}

		// HACK: fixes issue with invalid-feedback not displaying properly if the form-input component is wrapped in something (like input-group)
		// SHOULDDO: so if this gets fixed in future version of Bootstrap
		.invalid-feedback {
			display : block;
		}
	}
}
