@media print {
	html,
	body {
		background : white !important;
	}

	// HACK: hide all elements whose ancestors all don't have the print-element class
	// SHOULDDO: figure out a more general way to do this
	body.print-element {
		> :not(.print-element),
		> :not(.print-element) > :not(.print-element),
		> :not(.print-element) > :not(.print-element) > :not(.print-element),
		> :not(.print-element) > :not(.print-element) > :not(.print-element) > :not(.print-element),
		> :not(.print-element) > :not(.print-element) > :not(.print-element) > :not(.print-element) > :not(.print-element) {
			display : none;
		}
	}

	#root {
		padding-top : 0 !important;
	}

	[data-printable-modal] {
		max-height : none;
		inset      : 0;
		min-width  : none;
		z-index    : 10000;

		.modal {
			position : absolute;
			overflow : visible !important;
		}

		.modal-dialog {
			// only make the modal fit the entire screen for xl
			&.modal-xl {
				max-width  : initial;
				max-height : none;
				margin     : 0;
			}
		}

		.modal-header {
			.close {
				display : none;
			}
		}

		.modal-backdrop {
			display : none;
		}
	}

	.credit-report {
		border : 0;
	}
}
