// default class added by the v-lcb-busy directive
.lcb-busy {
	position : relative;
	color    : transparent !important;	// attempt to hide any existing text

	&::after {
		position            : absolute;
		top                 : 50%;
		left                : 50%;
		width               : 30px;
		height              : 30px;
		transform           : translate3d(-50%, -50%, 0);
		border              : 3px solid $border-color;
		border-radius       : 50%;
		border-bottom-color : transparent;
		animation           : 1s linear infinite lcb-busy-spinner;
		content             : "";
	}
}

@keyframes lcb-busy-spinner {
	0% {
		transform : translate3d(-50%, -50%, 0) rotate(0deg);
	}

	100% {
		transform : translate3d(-50%, -50%, 0) rotate(360deg);
	}
}
