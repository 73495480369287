/**
 * Bootstrap Overrides
 **/
@import "variables";
@import "mixins";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-vue/src/index";

// GENERAL
// SHOULDDO What is this accomplishing?
.col-form-label-sm {
	vertical-align : middle;
	overflow       : hidden;
	text-overflow  : ellipsis;
	white-space    : nowrap;
}

.sticky-top {
	@include on-mobile {
		top : $header-height-default;
	}
}

.sticky-bottom {
	position : sticky;
	bottom   : 0;
	z-index  : $z-index-sticky;
}

.d-flex-center {
	display         : flex !important;
	align-items     : center !important;
	justify-content : center !important;
}

input[readonly]:focus-visible {
	outline : none;
}

.font-weight-semi-bold {
	font-weight : 500;
}

// CHECKBOX
.custom-checkbox {
	.custom-control-label::before,
	.custom-control-label::after {
		top    : 0.15rem; // Adjust label height
		width  : 1.25rem;
		height : 1.25rem;
	}
}

// SELECT
.custom-select,
.custom-select-sm {
	// hide the up/down arrows if not hovering over the input element (allows more of the input value to show most of the time)
	&:not(:hover) {
		padding-right    : unset;
		background-image : initial;
	}

	// Overrides Chrome's user style of 0.7 opacity
	&:disabled {
		opacity : 1;
	}
}

// SWITCH
// increase the size of the switch toggle
.custom-switch {
	.custom-control-label {
		padding-left : 0.5rem;
	}

	.custom-control-label::before {
		transform        : scale(1.3);
		background-color : $custom-control-indicator-border-color;
		transform-origin : left;
	}

	.custom-control-label::after {
		transform        : scale(1.3);
		background-color : $white;
		transform-origin : left;
	}

	& .custom-control-input:checked ~ .custom-control-label::after {
		transform        : translateX(1rem) scale(1.3) !important;
		transform-origin : left;
	}
}

// FILE
// SHOULD DO Get Rid of this and use SM
.custom-file {
	.custom-file-label {
		height    : calc(1.5em + 0.75rem + 1px); // was +2px but that caused a 1px size difference compared to small buttons and inputs
		font-size : 12px;
		overflow  : hidden;
	}
}

/* stylelint-disable-next-line selector-class-pattern */
.vdp-datepicker__calendar {
	.cell {
		&.highlighted {
			background : $light-teal !important;
			color      : $body-color !important;
		}

		&.selected {
			background : $primary !important;
			color      : $white !important;
		}

		&:not(.blank, .disabled, .selected) {
			&.day,
			&.month,
			&.year {
				&:hover {
					color  : $primary !important;
					border : 1px solid transparent !important;
				}
			}
		}
	}

	header {
		.next,
		.prev {
			&::after {
				width  : 1rem;
				height : 1rem;
				margin : 0 !important;
				border : 0 !important;
			}

			&.disabled::after {
				filter : invert(94%) sepia(5%) saturate(167%) hue-rotate(169deg) brightness(97%) contrast(91%);
			}
		}

		.next::after {
			background : url('../../assets/icons/forward-arrow.svg') no-repeat center !important;
		}

		.prev::after {
			background : url('../../assets/icons/back-arrow.svg') no-repeat center !important;
		}

		.next,
		.prev,
		.up {
			&:not(.disabled):hover {
				background : transparent !important;
				filter     : invert(33%) sepia(97%) saturate(1204%) hue-rotate(156deg) brightness(94%) contrast(99%);
			}
		}
	}
}


.b-popover.text-preformatted,
.b-popover .text-preformatted {
	max-width : initial;		// popovers with pre-formatted text can easily be wider than the default max-width
}

// SHOULD DO Standardize this;
.tabs.tabs-large {
	.nav-tabs {
		.nav-item .nav-link {
			display          : flex;
			height           : 100%;
			background-color : $gray-100;
			border           : $border-width solid $border-color;
			border-bottom    : none;
			font-weight      : bold;
			align-items      : center;
			justify-content  : center;

			&.active {
				background-color : $white;
			}

			@include on-desktop {
				padding : 1rem;
			}
		}

		&.nav-justified {
			.nav-item {
				max-width : 300px;
			}
		}
	}
}

.carousel {
	.carousel-control-next-icon {
		width            : 10px;
		height           : 20px;
		background-image : url('../../assets/icons/forward-arrow.svg') !important;
	}

	.carousel-control-prev-icon {
		width            : 10px;
		height           : 20px;
		background-image : url('../../assets/icons/back-arrow.svg') !important;
	}
}


// Alerts
.alert {
	color : $body-color !important;
}
