$sm-screen : 576px;
$md-screen : 768px;
$lg-screen : 992px;
$xl-screen : 1200px;

@mixin on-desktop($size: $sm-screen) {
	@media screen and (min-width: $size) { @content; }
}

@mixin on-mobile() {
	@media screen and (width <= calc($sm-screen - 1px)) { @content; }
}

@mixin smaller-than($size: $md-screen) {
	@media screen and (width <= calc($size - 1px)) { @content; }
}
