@import "clickable", "media";

@mixin placeholder() {
	&::placeholder {
		@content;
	}
}

@mixin hide-scrollbar() {
	-ms-overflow-style : none;
	scrollbar-width    : none;

	&::-webkit-scrollbar {
		display : none;
	}
}
