.title,
.page-title {
	font-size   : $font-size-lg;
	font-weight : bold;
}

.title-highlight {
	font-size : 1.5rem;

	&::after {
		display          : block;
		width            : 100px;
		height           : 2px;
		margin-top       : 0.5rem;
		background-color : $primary;
		content          : "";
	}
}

.app-content .page-title {
	font-size : $font-size-xl;
}

.text-preformatted {
	white-space : pre;
}

.text-preformatted-line {
	white-space : pre-line !important;
}

.text-decoration-line-through {
	text-decoration : line-through;
}

@each $size, $value in $text-sizes {
	.text-#{$size} {
		font-size : $value !important;
	}
}

@each $size, $value in $border-widths {
	.border-#{$size} {
		border-width : $value !important;
	}
}

.outline-none {
	outline : none;
}

@mixin btn-variant($name, $color:'') {
	&-#{$name} {
		color : $color;

		&[disabled],
		&.disabled {
			color : $color;
		}

		&:not([disabled]),
		&:not(.disabled) {
			@include clickable-color($color, 20%);
		}
	}
}

.btn-text {
	@include btn-variant('danger', $danger);
	@include btn-variant('success', $success);
	@include btn-variant('warning', $warning);
	@include btn-variant('info', $info);
	@include btn-variant('brand', $brand);
	@include btn-variant('primary', $primary);
	@include btn-variant('secondary', $secondary);
	@include btn-variant('default', $default);
}

.tab-card {
	border-top              : 0;
	border-top-left-radius  : 0;
	border-top-right-radius : 0;
}

// highlight elements with auto-ids for easy identification
body.highlight-auto-ids *[data-auto-id] {
	outline : 2px solid red;

	&:hover {
		background : rgb(255 0 0 / 30%) !important;
		box-shadow : 0 0 10px 10px rgb(255 0 0 / 50%) !important;
		cursor     : pointer;
	}
}

.highlight-auto-id {
	background : rgb(0 168 14 / 50%) !important;
	box-shadow : 0 0 10px 10px rgb(0 168 14 / 50%) !important;
}

.hide-scrollbar {
	@include hide-scrollbar;
}

@media screen {
	.clickable {
		@include clickable;
	}

	.clickable-scale {
		@include clickable-scale;
	}

	.clickable-color-primary {
		@include clickable-color($primary);
	}

	.clickable-background-color-primary {
		@include clickable-background-color($primary);
	}

	.not-clickable {
		cursor : not-allowed;
	}
}

.dropdown.icon-dropdown {
	.dropdown-toggle,
	.dropdown-item {
		.b-icon.bi {
			font-size : $font-size-xl;
		}
	}

	.dropdown-menu {
		min-width : unset;
	}
}

.carousel-dots {
	.carousel-indicators {
		margin : 0;

		li {
			position         : relative;
			width            : 0.25rem;
			height           : 0.25rem;
			margin           : 0 0.25rem;
			padding          : 0.25rem;
			background-color : $gray-500;
			border-radius    : 50%;
			opacity          : 1;

			&.active {
				background-color : $primary;
			}
		}
	}
}

.card.carousel-card {
	.carousel {
		display        : flex;
		flex-direction : column;

		&:has(.carousel-control-next, .carousel-control-prev) {
			margin : 0 1rem; // give extra space for the controls
		}

		.carousel-inner {
			display        : flex;
			align-items    : center;
			flex-direction : row;
			flex-grow      : 1;
		}

		.carousel-control-prev,
		.carousel-control-next {
			width  : auto;
			margin : 0 -1rem;
		}

		.carousel-indicators {
			position : static;
			margin   : 0.75rem 0 -0.5rem;
		}
	}

	&.h-100 .carousel {
		height : 100%;
	}
}

.tabs.tabs-underline {
	$tab-border : 2px;

	.nav-tabs {
		border-bottom : $tab-border solid $gray-400;

		.nav-link {
			display          : flex;
			margin-bottom    : -$tab-border;
			background-color : transparent;
			color            : $body-color;
			border           : none;
			border-bottom    : $tab-border solid transparent;
			font-weight      : 700;
			align-items      : center;
			justify-content  : center;
		}

		.nav-link.active {
			background-color : transparent;
			color            : $primary;
			border-color     : $primary;
		}
	}
}

// ChartJs Colors
:root {
	--chart-font-family        : #{$font-family-base};
	--chart-debt-over-time-bar : #{$sapphire};
}
