@use "sass:color";

// FrontLobby colors
$midnight       : #152951;
$navy           : #284a6e;
$sky            : #dae1e7;
$sky-grey       : #f4f6f9;
$charcoal       : #222;
$light-grey     : #e6e6e6;
$teal           : #01909e;
$light-teal     : #d1e8eb;
$sapphire       : #7dbdc9;
$turquoise      : #b2e0ee;
$lightest-green : #28a745;
$rating-color   : #f6bb06;
$orange         : #f07f23;
$orange-light   : #f1ebe4;
$light-cyan     : #e1f2f3;

// bootstrap semantic colors
$green  : #198754;
$yellow : #ffc107;
$red    : #dc3545;
$cyan   : #0dcaf0;
$shadow : 0 15px 25px 5px rgb(0 0 0 / 22%);

// variables
$header-height-default : 60px; // the height of the main nav header

// Bootstrap Overrides
$white    : #fff;
$gray-100 : $sky-grey;
$gray-200 : #e9ecef;
$gray-300 : #dee2e6;
$gray-400 : #ced4da;
$gray-500 : #adb5bd;
$gray-600 : #6c757d;
$gray-700 : #495057;
$gray-800 : #343a40;
$gray-900 : #212529;
$black    : #000;

$success      : $lightest-green;
$warning      : $orange;
$danger       : $red;
$info         : $cyan;
$default      : $gray-600;
$brand        : $midnight;
$primary      : $teal;
$secondary    : $navy;
$theme-colors : (
	"default" : $default,
	"brand"   : $brand,
	"teal"    : $light-teal,
) !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold : 155 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark : $charcoal !default;

// overrides for LCB
$light-background-green : #e1f5d6;

#root.lcb {
	--brand     : #01311d;
	--secondary : #{$lightest-green};
}

// Options: Quickly modify global styling by enabling or disabling optional features.
$enable-shadows : true;

// Spacing: Control the default styling of most Bootstrap elements by modifying these variables.
// Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer  : 1rem !default;
$spacers : (
	0: 0,
	1: ($spacer * 0.25),
	2: ($spacer * 0.5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 5)
);

// Settings for the `<body>` element.
$body-bg    : $gray-100;
$body-color : $charcoal;

// Links: style anchor elements.
$link-hover-decoration : none;

// Components: define common padding and border radius sizes and more.
$border-width     : 1px;
$border-widths    : (
	1: 1px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px,
);
$border-color     : $gray-300;
$border-radius    : 0.25rem !default;
$border-radius-lg : 0.5rem !default;
$border-radius-sm : 0.2rem !default;

// Typography: Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif : 'Roboto', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !default;
$font-family-base       : $font-family-sans-serif;

$font-size-base : 1rem !default;
$font-size-2xl  : $font-size-base * 1.75;
$font-size-xl   : $font-size-base * 1.5;
$font-size-lg   : $font-size-base * 1.25;
$font-size-sm   : $font-size-base * 0.875;
$font-size-xs   : $font-size-base * 0.75;
$text-sizes     : (
	"xs"  : $font-size-xs,
	"sm"  : $font-size-sm,
	"md"  : $font-size-base,
	"lg"  : $font-size-lg,
	"xl"  : $font-size-xl,
	"2xl" : $font-size-2xl,
);
$text-muted     : $gray-600;

// Tables: customizes the `.table` component with basic values, each used across all table variations
$table-bg           : $white !default;
$table-border-width : $border-width;
$table-border-color : $border-color;

// Shadows
$box-shadow-sm : 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow    : 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg : 0 1rem 3rem rgba($black, 0.175) !default;

// Forms
$input-bg                     : $white;
$input-disabled-bg            : $gray-200;
$input-color                  : $gray-700;
$input-border-color           : $gray-400;
$custom-select-disabled-color : $input-color;
$input-box-shadow             : inset 0 1px 1px rgba($black, 0.075) !default;

// Z-index master list
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$z-index-dropdown       : 1000 !default;
$z-index-sticky         : 1020 !default;
$z-index-fixed          : 1030 !default;
$z-index-modal-backdrop : 1040 !default;
$z-index-modal          : 1050 !default;
$z-index-popover        : 1060 !default;
$z-index-tooltip        : 1070 !default;

// Nav
$nav-tabs-link-active-bg : $white;
$nav-width               : 255px;
$nav-width-collapsed     : 55px;

:root {
	--nav-width : #{$nav-width};
}

// Dropdown menu container and contents.
$dropdown-box-shadow : $shadow;

// Popovers
$popover-box-shadow : $shadow;

// Alerts
$alert-bg-level     : -11 !default; // lighter than the default
$alert-border-level : 0 !default;  // darker than the default
